// react
import './App.css';
import * as React from "react";
import {Component} from "react";

// images
import logo from './fz_logo_white.jpeg';
import band from './FZband.jpg';
import band_angle from './FZangle.jpg';
import band_wall from './FZwall.jpg';
// import band_purple from './FZpurple.jpg';
// import band_baw from './FZbaw.jpg';
// import band_mendo from './FZmendo.jpg';
// import band_lot from './FZlot.jpg';
// import band_outdoor from './FZoutdoor.jpg';

// material-ui
import {makeStyles} from '@mui/styles';
import {withStyles} from '@mui/styles';
import {Typography} from '@mui/material';
import {Container} from '@mui/material';
// import {ImageList,ImageListItem} from '@mui/material';

// fonts
import './fonts/Grock.otf';
import './fonts/Humanist522.ttf';
import './fonts/MarteksPersonalUse-RpeyA.otf';

// bandcamp
import BandcampPlayer from 'react-bandcamp'

// icons
import FacebookIcon from '@mui/icons-material/Facebook';
import YouTubeIcon from '@mui/icons-material/YouTube';

// // collage data 
// const itemData=[
//   {
//     img: band_purple,
//     title: 'Purple Life',
//   },
//   {
//     img: band_lot,
//     title: 'Parking Lot Mayhem',
//   },
//   {
//     img: band_baw,
//     title: 'Black and White Brass Friends',
//   },
//   {
//     img: band_wall,
//     title: 'BAND AT THE WALL',
//   },
//   {
//     img: band_mendo,
//     title: 'At the Mendocino Woodlands',
//   },
//   {
//     img: band_outdoor,
//     title: 'Honey',
//   }
// ]


// styles


// CSS component styling
// const useStyles=makeStyles(theme => ({
const styles=theme => ({
  // const useStyles=makeStyles({
  root: {
    backgroundColor: "black",
    color: "black"
  },
  mainContainer: {
    backgroundColor: "black",
    maxWidth: "lg",
    paddingRight: "0px!important",
    paddingLeft: "0px!important"
  },
  body: {
    backgroundColor: 'black',
    height: '200px',
    textAlign: 'center',
  },
  grid: {
    backgroundColor: 'black',
    color: 'black'
  },
  header: {
    backgroundColor: 'black',
    paddingTop: '15px',
    paddingBottom: '10px'
  },
  headerFontRight: {
    fontFamily: "Martek!important",
    fontSize: "1.2rem",
    color: "white",
    letterSpacing: "0.0138em",
    textAlign: "right!important",
  },
  headerFontLeft: {
    fontFamily: "Martek!important",
    fontSize: "1.2rem",
    color: "white",
    letterSpacing: "0.0138em",
    textAlign: "left!important",
  },
  headerFontCenter: {
    fontFamily: "Martek!important",
    fontSize: "1.2rem",
    color: "white",
    letterSpacing: "0.0138em",
    textAlign: "center!important",
  },
  containerFlavor: {
    paddingTop: "50px!important",
    paddingBottom: "30px!important",
    paddingLeft: "5%!important",
    paddingRight: "5%!important",
    backgroundColor: "black"
  },
  bandcampContainer: {
    paddingTop: "35px!important",
    paddingBottom: "77px!important",
    // 125 paddingBottom if using current band image above 
    paddingLeft: "5%!important",
    paddingRight: "5%!important",
    backgroundColor: "black"
  },
  bookingContainer: {
    paddingTop: "10px!important",
    paddingBottom: "30px!important",
    paddingLeft: "5%!important",
    paddingRight: "5%!important",
    backgroundColor: "black"
  },
  linkContainer: {
    paddingTop: "0px",
    paddingBottom: "0px"
  },
  paragraphFontLeft: {
    paddingRight: "20%",
    color: "white",
    paddingBottom: "5%",
    textalign: "left"
  },
  paragraphFontRight: {
    paddingLeft: "20%",
    color: "white",
    textAlign: "right",
    paddingBottom: "5%"
  },
  paragraphFontCenter: {
    paddingLeft: "20%",
    paddingRight: "20%",
    color: "white",
    textAlign: "center",
    paddingBottom: "5%"
  },
  paragraphFontLeftAlignCenter: {
    paddingLeft: "10%",
    paddingRight: "10%",
    color: "white",
    textAlign: "left",
    paddingBottom: "5%"
  },
  linkColors: {
    color: "white!important"
  }
});
// }));

// const isMobile=false;

// main App
class App extends Component {
  constructor(props) {
    super(props)

    // set initial state
    this.state={
      isMobile: false
    }
  }
  // event handlder to capture small screen widths / mobile
  componentDidMount() {
    window.addEventListener('resize',() => {
      this.setState({
        isMobile: window.innerWidth<1000
      });
    },false);
  };
  // site contents
  render() {
    const {classes}=this.props;
    return (
      <div style={{background: '#19111f'}}>
        {/* <Container className={classes.metaContainer}> */}
        <Container className={classes.mainContainer}>
          {/* Header */}
          <header className={classes.header}>
            <center>
              <img justify='center' src={logo} width='35%' alt="Logo design by Rosie Steffy" />
            </center>
          </header>
          {/* Band image */}
          <img src={band} width='100%' alt="Credit: April Renae" />
          {/* Main text */}
          <Container className={classes.containerFlavor} maxWidth="lg">
            <p className={`${!this.state.isMobile? classes.headerFontLeft:classes.headerFontCenter}`}>BAY AREA BALKAN BRASS</p>
            <Typography className={`${!this.state.isMobile? classes.paragraphFontLeft:classes.paragraphFontLeftAlignCenter}`}>
              Fanfare Zambaleta (roughly meaning crazy street party with brass)
              is an eight piece Balkan Brass band specializing in spirited music
              of the Serbian, Macedonian, Bulgarian, Greek and especially the Romani
              people. Combining a love of the classic brass bands of the past with
              the modern sounds coming out of the Balkans, Fanfare Zambaleta brings
              an irresistible blend of old and new to get you dancing! They've learned
              from some of the greats including Demiran Ćerimović, Džambo Aguševi
              Orkestar and Nizo Alimov (of Kočani Orkestar) and are now embarking on
              a new journey to write original tunes inspired by their deep love of the music.
            </Typography>
            <p className={`${!this.state.isMobile? classes.headerFontRight:classes.headerFontCenter}`}>MUSICIANS</p>
            <Typography className={`${!this.state.isMobile? classes.paragraphFontRight:classes.paragraphFontLeftAlignCenter}`}>
              The band is directed by Gregory Masaki Jenkins (alto sax, clarinet)
              and features Joey Friedman (alto sax), Noah Levitt and Max Miller-Loran
              (trumpets), Peter Bonos and Adam Waite (mid horns), Evan Stuart (sousaphone)
              and Ivan Velev (percussion, vocals).
            </Typography>
          </Container>
          {/* Tano shot */}
          <img src={band_angle} width='100%' alt="Credit: Tano Brock" />
          {/* Bandcamp */}
          <Container className={classes.bandcampContainer}>
            <p className={classes.headerFontCenter}>LISTEN</p>
            <center>
              <BandcampPlayer
                backgroundColor="gray"
                album="3396076375"
                bgcol="333333"
                linkcol="FFFFFF"
                size="large"
                width="60%"
                height="275px"
                border="0px"
                artwork="small"
                tracklist="true"
                title="Fanfare Zambaleta"
              />
            </center>
          </Container>
          {/* Current band */}
          <img justify='center' src={band_wall} width='100%' alt="" />
          {/* Images */}
          {/* <Container className={classes.containerFlavorXL}>
          <center>
            <ImageList sx={{width: 500,height: 400}} cols={3} rowHeight={164}>
              {itemData.map((item) => (
                <ImageListItem key={item.img}>
                  <img
                    src={`${item.img}?w=220&h=164&fit=crop&auto=format`}
                    srcSet={`${item.img}?w=100&h=164&fit=crop&auto=format&dpr=2 2x`}
                    alt={item.title}
                    loading="lazy"
                  />
                </ImageListItem>
              ))}
            </ImageList>
          </center>
        </Container> */}
          {/* Booking */}
          {/* className={classes.containerFlavorTone} */}
          <Container className={classes.bookingContainer}>
            <p className={classes.headerFontCenter}>BOOKING</p>
            <Typography className={classes.paragraphFontCenter}>
              Gregory Masaki Jenkins {<br />} (650)-450-0418  {<br />} fanfarezambaleta@gmail.com
              {<br />}{<br />}
            </Typography>

            <Typography className={classes.paragraphFontCenter}>
              <a className={classes.linkColors} href="https://www.youtube.com/user/FanfareZambaleta">
                <YouTubeIcon fontSize="medium" />
              </a>
              &nbsp; &nbsp;
              <a className={classes.linkColors} href="https://www.facebook.com/FanfareZambaleta/">
                <FacebookIcon fontSize="medium" />
              </a>
            </Typography>
          </Container>
          {/* </Container> */}
        </Container>
      </div>
    );
  }
}

// export default App;
//export default App;
export default withStyles(styles,{withTheme: true})(App);